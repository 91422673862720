import { AutomatitCarousel } from './automatit_carousel.m';

const homeAboutCaro = {
	caro: document.querySelector('#home_about_caro'),
	init() {
		window.facImgsCaro = AutomatitCarousel({
			element: this.caro,
			images: [
				{src: "/dist/images/content/home_silder_1.jpg", alt: ""},
				{src: "/dist/images/content/home_silder_2.jpg", alt: ""},
				{src: "/dist/images/content/home_silder_3.jpg", alt: ""},
				{src: "/dist/images/content/home_silder_4.jpg", alt: ""},
			],
			imagesAsBackgrounds: true,
			useChevrons: false,
			showDots: true,
		});
	}
};

export const initHomeAboutCaro = () => homeAboutCaro.init();
