import { contactSubmit } from './contact.js';
import { initHomeAboutCaro } from './home.js';

if (document.getElementById('home_about_caro')) {
	initHomeAboutCaro();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}
